import { HStack, Text } from '@chakra-ui/react';
import moment from 'moment';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Shipment } from '../../types/Shipment';
import { StatusTag } from './StatusTag';
import { TransportModeTag } from './TransportModeTag';

interface IMainInformationProps {
  shipment: Shipment | null;
}

export const MainInformationContent: FunctionComponent<
  IMainInformationProps
> = ({ shipment }) => {
  const { t } = useTranslation();

  if (shipment === null) {
    return <Text textAlign="center">{t('Track your shipment')}</Text>;
  }

  return (
    <>
      <Text>{shipment?.reference}</Text>
      <HStack flexWrap="wrap" justifyContent="center">
        <Text>{shipment?.origin ?? t('T.B.D.')}</Text>
        <Text>-</Text>
        <Text>{shipment?.destination ?? t('T.B.D.')}</Text>
      </HStack>
      <TransportModeTag transportMode={shipment.transportMode} />
      <StatusTag shipmentStatus={shipment.status} />
      {shipment.locations?.at(-1)?.date && (
        <HStack justifyContent="center" as="span" pt="3" wrap="wrap">
          <Text>
            {shipment.locations?.at(-1)?.completed ? t('ATA') : t('ETA')}:
          </Text>
          <Text textAlign="center">
            {moment(shipment.locations?.at(-1)?.date).format('dddd, ')}
            {moment(shipment.locations?.at(-1)?.date)
              .format('MMM D')
              .toUpperCase()}
          </Text>
        </HStack>
      )}
      {shipment.locations?.at(-1)?.date == null && (
        <HStack justifyContent="center" as="span" pt="3" wrap="wrap">
          <Text>{t('ETA')}:</Text>
          <Text>{t('T.B.D.')}</Text>
        </HStack>
      )}
    </>
  );
};
