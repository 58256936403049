import { Icon, IconProps } from '@chakra-ui/react';

export const RailIcon = (props: IconProps) => (
  <Icon viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      d="M10.5,0 C5.25,0 0,0.657894737 0,5.26315789 L0,17.7631579 C0,20.3026316 2.060625,22.3684211 4.59375,22.3684211 L2.625,24.3421053 L2.625,25 L5.25,25 L7.875,22.3684211 L13.125,22.3684211 L15.75,25 L18.375,25 L18.375,24.3421053 L16.40625,22.3684211 C18.939375,22.3684211 21,20.3026316 21,17.7631579 L21,5.26315789 C21,0.657894737 15.75,0 10.5,0 Z M10.5,2.63157895 C15.106875,2.63157895 17.01,3.26315789 17.810625,3.94736842 L3.189375,3.94736842 C3.99,3.26315789 5.893125,2.63157895 10.5,2.63157895 Z M2.625,6.57894737 L9.1875,6.57894737 L9.1875,10.5263158 L2.625,10.5263158 L2.625,6.57894737 Z M18.375,17.7631579 C18.375,18.8552632 17.495625,19.7368421 16.40625,19.7368421 L4.59375,19.7368421 C3.504375,19.7368421 2.625,18.8552632 2.625,17.7631579 L2.625,13.1578947 L18.375,13.1578947 L18.375,17.7631579 Z M18.375,10.5263158 L11.8125,10.5263158 L11.8125,6.57894737 L18.375,6.57894737 L18.375,10.5263158 Z"
    />
  </Icon>
);
