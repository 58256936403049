import { CheckIcon } from '@chakra-ui/icons';
import { Text } from '@chakra-ui/react';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { ShipmentStatus } from '../../enums/ShipmentStatus';
import { ShipmentStatusTag } from '../../theme/customComponents/ShipmentStatusTag';

interface IStatusTagProps {
  shipmentStatus?: ShipmentStatus;
}

export const StatusTag: FunctionComponent<IStatusTagProps> = ({
  shipmentStatus,
}) => {
  const { t } = useTranslation();

  const getStatusText = (status?: ShipmentStatus) => {
    switch (status) {
      case ShipmentStatus.Canceled:
        return t('Canceled');
      case ShipmentStatus.Delayed:
        return t('Delayed');
      case ShipmentStatus.Delivered:
        return t('Delivered');
      case ShipmentStatus.InTransit:
        return t('In Transit');
      case ShipmentStatus.OnTime:
        return t('On Time');
      case ShipmentStatus.Booked:
      default:
        return t('Booked');
    }
  };

  const getStatusColor = (status?: ShipmentStatus) => {
    switch (status) {
      case ShipmentStatus.Canceled:
        return 'linear(to-l, danger.500, danger.600)';
      case ShipmentStatus.Delayed:
        return 'linear(to-l, warning.500, warning.600)';
      case ShipmentStatus.Delivered:
      case ShipmentStatus.InTransit:
      case ShipmentStatus.OnTime:
        return 'linear(to-l, success.500, success.600)';
      case ShipmentStatus.Booked:
      default:
        return 'linear(to-l, gray.500, gray.600)';
    }
  };

  return (
    <ShipmentStatusTag bgGradient={getStatusColor(shipmentStatus)}>
      <Text
        textAlign="center"
        color={
          shipmentStatus === ShipmentStatus.Delivered ? 'success.400' : 'white'
        }
      >
        {getStatusText(shipmentStatus)}
      </Text>
      {shipmentStatus === ShipmentStatus.Delivered && (
        <CheckIcon color="success.400" />
      )}
    </ShipmentStatusTag>
  );
};
