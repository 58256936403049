import { extendTheme } from '@chakra-ui/react';
import { theme as baseTheme } from '@chakra-ui/theme';
import { merge } from 'lodash';
import { Button } from './components/Button';
import { Divider } from './components/Divider';
import { Input } from './components/Input';

export const theme = extendTheme(
  {
    styles: merge(
      {
        global: {
          body: {
            background: 'white',
            color: 'primary.500',
            fontSize: 'md',
            fontWeight: 'semibold',
          },
        },
      },
      CONFIG.styles,
    ),
    layerStyles: {
      primary: {
        background: 'primary.500',
        color: 'white',
      },
      primaryGradient: {
        bgGradient: 'linear(to-r, primary.500, primary.600)',
        color: 'white',
      },
      light: {
        background: 'secondary.50',
        color: 'secondary.500',
      },
      column: {
        px: { base: 6, md: 16 },
        pt: { base: 8, md: 10 },
        pb: 12,
      },
      thin: {
        alignSelf: 'center',
        boxSizing: 'content-box',
        maxWidth: 460,
        px: 6,
        width: `calc(100% - ${baseTheme.space[6]} * 2)`,
      },
    },
    colors: {
      danger: {
        '500': '#F70E0E',
        '600': '#DB0C12',
      },
      success: {
        '400': '#BEFFA3',
        '500': '#4BC200',
        '600': '#26A000',
      },
      warning: {
        '500': '#FCAD00',
        '600': '#F09400',
      },
      blue: {
        '500': '#5A95FF',
        '600': '#3A65FD',
      },
      gray: {
        '500': '#B8B8B8',
        '600': '#9C9C9C',
        '1000': '#333333',
      },
      grayAlpha: {
        '500': 'rgba(82,82,82,50)',
      },
      secondary: {
        '500': '#00B6E8',
        '600': '#00B6E8',
      },
      primary: {
        '500': '#002C61',
        '600': '#002C61',
      },
      ...CONFIG.colors,
    },
    sizes: {
      xl: '37.625rem',
    },
    fonts: {
      heading: 'Default Font, sans-serif',
      body: 'Default Font, sans-serif',
    },
    fontSizes: {
      '3xl': '1.375rem',
    },
    components: {
      Button,
      Input,
      Divider,
    },
  },
  baseTheme,
);
