import { LocationType } from '../enums/LocationType';
import { ShipmentStatus } from '../enums/ShipmentStatus';
import { TransportMode } from '../enums/TransportMode';
import { Shipment } from '../types/Shipment';

export const singleStationCheckedShipment: Shipment = {
  origin: 'Rio De Janeiro',
  destination: 'Visakhapatanam',
  reference: 'T21190160006',
  status: ShipmentStatus.OnTime,
  transportMode: TransportMode.Sea,
  locations: [
    {
      id: 'LegID1',
      type: LocationType.Delivery,
      completed: true,
      city: 'Hamburg',
      country: 'Germany',
      countryCode: 'de',
      date: new Date('2023-02-01'),
    },
  ],
};
