import { Divider, Flex, HStack, Img, Text, VStack } from '@chakra-ui/react';
import moment from 'moment';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { ShipmentLocation } from '../../types/ShipmentLocation';
import { CheckCircleIcon } from '../Icons/CheckCircleIcon';
import { HollowCircleIcon } from '../Icons/HollowCircleIcon';

interface ITimelineElementProps {
  locationInformation: ShipmentLocation;
  isLast: boolean;
}

export const TimelineElement: FunctionComponent<ITimelineElementProps> = ({
  locationInformation,
  isLast,
}) => {
  const { t } = useTranslation('base');
  const textOpacity = locationInformation.completed ? '1' : '0.5';
  return (
    <Flex width="100%" paddingBottom="16">
      <VStack marginRight="5">
        {!locationInformation.completed && (
          <HollowCircleIcon width="40px" height="40px" />
        )}
        {locationInformation.completed && (
          <CheckCircleIcon width="40px" height="40px" />
        )}
        {!isLast && (
          <Divider orientation="vertical" variant="vertical" height="150%" />
        )}
      </VStack>
      <VStack alignItems="flex-start" fontSize="xl" color={'gray.1000'} mt="1">
        <HStack marginBottom="1">
          <Text
            fontSize="3xl"
            fontWeight="700"
            lineHeight="32px"
            opacity={textOpacity}
          >
            {locationInformation.type.toString()}
          </Text>
          {locationInformation.countryCode && (
            <Img
              src={`/countryFlags/${locationInformation.countryCode.toLowerCase()}.svg`}
              height="16px"
              border="0.2px solid grey"
            />
          )}
        </HStack>
        <Text margin="0 !important" opacity={textOpacity} fontWeight="500">
          {locationInformation.city ?? t('T.B.D.')},{' '}
          {locationInformation.country ?? t('T.B.D.')}
        </Text>
        {locationInformation.date && (
          <Text
            margin="0 !important"
            textAlign="center"
            opacity={textOpacity}
            fontWeight="500"
          >
            {moment(locationInformation.date).format('dddd, ')}
            {moment(locationInformation.date).format('MMM D').toUpperCase()}
          </Text>
        )}
        {locationInformation.date == null && !locationInformation.completed && (
          <Text
            margin="0 !important"
            textAlign="center"
            opacity={textOpacity}
            fontWeight="500"
          >
            {t('T.B.D.')}
          </Text>
        )}
      </VStack>
    </Flex>
  );
};
