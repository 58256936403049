/// <summary>
/// Types of shipment legs
/// Pickup: Vorlauf
/// Delivery: Nachlauf
/// Other: Hauptlauf
/// </summary>
export enum LocationType {
  Pickup = 'Pickup',
  Departure = 'Departure',
  Arrival = 'Arrival',
  Customs = 'Customs',
  Delivery = 'Delivery',
}
