import { LocationType } from '../enums/LocationType';
import { ShipmentStatus } from '../enums/ShipmentStatus';
import { TransportMode } from '../enums/TransportMode';
import { Shipment } from '../types/Shipment';

export const longTextShipment: Shipment = {
  origin: 'Rio De Janeiro',
  destination: 'Visakhapatanam',
  reference: 'T21190160006',
  status: ShipmentStatus.OnTime,
  transportMode: TransportMode.Sea,
  locations: [
    {
      id: 'LegID1',
      type: LocationType.Pickup,
      completed: true,
      city: 'Ouagadougou',
      country: 'Burkina Faso',
      countryCode: 'bf',
      date: new Date('2023-02-01'),
    },
    {
      id: 'LegID2',
      type: LocationType.Departure,
      completed: true,
      city: 'Ouagadougou',
      country: 'Burkina Faso',
      countryCode: 'bf',
      date: new Date('2023-02-02'),
    },
    {
      id: 'LegID3',
      type: LocationType.Arrival,
      completed: true,
      city: 'Frankfurt am Main',
      country: 'Papua-Neuguinea',
      countryCode: 'pg',
      date: new Date('2023-02-10'),
    },
    {
      id: 'LegID4',
      type: LocationType.Delivery,
      completed: false,
      city: 'Frankfurt am Main',
      country: 'Papua-Neuguinea',
      countryCode: 'pg',
      date: new Date('2023-02-11'),
    },
  ],
};
