import { Icon, IconProps } from '@chakra-ui/react';

export const HollowCircleIcon = (props: IconProps) => (
  <Icon viewBox="0 0 40 40" {...props}>
    <rect
      x="1.5"
      y="1.5"
      width="37"
      height="37"
      rx="18.5"
      fill="#FFF"
      stroke="currentColor"
      strokeWidth="3"
      fillRule="evenodd"
    />
  </Icon>
);
