import { HStack, Text } from '@chakra-ui/react';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { TransportMode } from '../../enums/TransportMode';
import { PlaneIcon } from '../Icons/PlaneIcon';
import { RailIcon } from '../Icons/RailIcon';
import { ShipIcon } from '../Icons/ShipIcon';
import { TruckIcon } from '../Icons/TruckIcon';

interface ITransportModeTagProps {
  transportMode?: TransportMode;
}

export const TransportModeTag: FunctionComponent<ITransportModeTagProps> = ({
  transportMode,
}) => {
  const { t } = useTranslation();

  const getTransportModeIcon = (mode?: TransportMode) => {
    switch (mode) {
      case TransportMode.Air:
        return <PlaneIcon w="2rem" h="2rem" />;
      case TransportMode.Sea:
        return <ShipIcon w="2rem" h="2rem" />;
      case TransportMode.Truck:
        return <TruckIcon w="2rem" h="2rem" />;
      case TransportMode.Rail:
        return <RailIcon w="2rem" h="2rem" />;
      default:
        return null;
    }
  };

  const getTransportModeText = (mode?: TransportMode) => {
    switch (mode) {
      case TransportMode.Air:
        return t('Air');
      case TransportMode.Sea:
        return t('Sea');
      case TransportMode.Truck:
        return t('Truck');
      case TransportMode.Rail:
        return t('Rail');
      default:
        return null;
    }
  };

  return (
    <HStack alignItems="center">
      {getTransportModeIcon(transportMode)}
      <Text>{getTransportModeText(transportMode)?.toUpperCase()}</Text>
    </HStack>
  );
};
