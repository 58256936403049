import { Icon, IconProps } from '@chakra-ui/react';

export const PlaneIcon = (props: IconProps) => (
  <Icon viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      d="M25.6,17.92 L25.6,15.36 L14.8210526,8.96 L14.8210526,1.92 C14.8210526,0.8576 13.9183158,0 12.8,0 C11.6816842,0 10.7789474,0.8576 10.7789474,1.92 L10.7789474,8.96 L0,15.36 L0,17.92 L10.7789474,14.72 L10.7789474,21.76 L8.08421053,23.68 L8.08421053,25.6 L12.8,24.32 L17.5157895,25.6 L17.5157895,23.68 L14.8210526,21.76 L14.8210526,14.72 L25.6,17.92 Z"
    />
  </Icon>
);
