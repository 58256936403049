import { chakra, Link } from '@chakra-ui/react';

export const UpsellLink = chakra(Link, {
  baseStyle: {
    paddingY: '3',
    paddingX: '5',
    maxWidth: '18.75rem',
    width: '100%',
    background: 'primary.500',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: '8',
  },
});
