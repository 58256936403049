import { Icon, IconProps } from '@chakra-ui/react';

export const TruckIcon = (props: IconProps) => (
  <Icon viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      d="M26.030303,11 L22.3939394,11 L22.3939394,6 L5.42424242,6 C4.09090909,6 3,7.125 3,8.5 L3,22.25 L5.42424242,22.25 C5.42424242,24.325 7.04848485,26 9.06060606,26 C11.0727273,26 12.6969697,24.325 12.6969697,22.25 L19.969697,22.25 C19.969697,24.325 21.5939394,26 23.6060606,26 C25.6181818,26 27.2424242,24.325 27.2424242,22.25 L29.6666667,22.25 L29.6666667,16 L26.030303,11 Z M25.4242424,12.875 L27.8,16 L22.3939394,16 L22.3939394,12.875 L25.4242424,12.875 Z M9.06060606,23.5 C8.39393939,23.5 7.84848485,22.9375 7.84848485,22.25 C7.84848485,21.5625 8.39393939,21 9.06060606,21 C9.72727273,21 10.2727273,21.5625 10.2727273,22.25 C10.2727273,22.9375 9.72727273,23.5 9.06060606,23.5 Z M11.7515152,19.75 C11.0848485,18.9875 10.1393939,18.5 9.06060606,18.5 C7.98181818,18.5 7.03636364,18.9875 6.36969697,19.75 L5.42424242,19.75 L5.42424242,8.5 L19.969697,8.5 L19.969697,19.75 L11.7515152,19.75 Z M23.6060606,23.5 C22.9393939,23.5 22.3939394,22.9375 22.3939394,22.25 C22.3939394,21.5625 22.9393939,21 23.6060606,21 C24.2727273,21 24.8181818,21.5625 24.8181818,22.25 C24.8181818,22.9375 24.2727273,23.5 23.6060606,23.5 Z"
    />
  </Icon>
);
