import { ComponentStyleConfig } from '@chakra-ui/react';

export const Button: ComponentStyleConfig = {
  baseStyle: {
    borderRadius: '28',
    fontSize: 'md',
  },
  variants: {
    search: {
      background: 'primary.500',
      paddingX: '10',
      paddingY: '6',
      _hover: {
        background: 'primary.600',
      },
      _focus: {
        background: 'primary.600',
      },
    },
  },
};
