import { Shipment } from '../types/Shipment';
import {
  allStationsEmptyCheckedShipment,
  allStationsEmptyUncheckedShipment,
  emptyShipment,
  longTextShipment,
  multiCountryHalfCheckedShipment,
  singleCountryCheckedShipment,
  singleCountryUncheckedShipment,
  singleStationCheckedShipment,
  singleStationUncheckedShipment,
} from '.';

export const setMockShipment = (
  searchString: string,
  setShipment: (shipment: Shipment | null) => void,
  setShipmentNotFound: (notFound: boolean) => void,
) => {
  switch (searchString) {
    case 'TEST_NO_SHIPMENT': {
      setShipment(null);
      setShipmentNotFound(true);
      break;
    }
    case 'TEST_SINGLE_STATION_UNCHECKED': {
      setShipment(singleStationUncheckedShipment);
      break;
    }
    case 'TEST_SINGLE_STATION_CHECKED': {
      setShipment(singleStationCheckedShipment);
      break;
    }
    case 'TEST_SINGLE_COUNTRY_CHECKED': {
      setShipment(singleCountryCheckedShipment);
      break;
    }
    case 'TEST_SINGLE_COUNTRY_UNCHECKED': {
      setShipment(singleCountryUncheckedShipment);
      break;
    }
    case 'TEST_MULTI_COUNTRY_HALF_CHECKED': {
      setShipment(multiCountryHalfCheckedShipment);
      break;
    }
    case 'TEST_ALL_STATIONS_EMPTY_CHECKED': {
      setShipment(allStationsEmptyCheckedShipment);
      break;
    }
    case 'TEST_ALL_STATIONS_EMPTY_UNCHECKED': {
      setShipment(allStationsEmptyUncheckedShipment);
      break;
    }
    case 'TEST_EMPTY': {
      setShipment(emptyShipment);
      break;
    }
    case 'TEST_LONG_TEXTS': {
      setShipment(longTextShipment);
      break;
    }
    default: {
      setShipment(null);
      setShipmentNotFound(true);
    }
  }
};
