import { Box, Link } from '@chakra-ui/react';

export const Footer = () => (
  <Box
    background="secondary.500"
    display="flex"
    flexDirection="row"
    justifyContent="center"
  >
    {CONFIG.footer.map((linkInfo) => (
      <Link
        target="_blank"
        mx="4"
        marginBottom="4"
        key={linkInfo.title}
        href={linkInfo.link}
        fontSize="xs"
        color="white"
      >
        {linkInfo.title}
      </Link>
    ))}
  </Box>
);
