import i18n, { use } from 'i18next';
import { merge } from 'lodash';
import { initReactI18next } from 'react-i18next';
import { en } from './en';

const resources = merge(
  {
    en,
  },
  CONFIG.translations,
);

use(initReactI18next).init({
  fallbackLng: 'en',
  resources,
  defaultNS: 'base',
});

// eslint-disable-next-line import/no-default-export
export default i18n;
