import { chakra, HStack } from '@chakra-ui/react';

export const ShipmentStatusTag = chakra(HStack, {
  baseStyle: {
    width: '100%',
    paddingY: '2',
    paddingX: '6',
    marginY: '6',
    borderRadius: '44',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '2',
    fontWeight: 'bold',
  },
});
