import { Image, HStack, StackProps, ThemingProps } from '@chakra-ui/react';
import { FunctionComponent } from 'react';

export const Header: FunctionComponent<StackProps & ThemingProps> = ({
  ...props
}) => (
  <HStack justifyContent="center" layerStyle="column" {...props}>
    <Image
      src={CONFIG.images.logo.path}
      alt={CONFIG.images.logo.title}
      width="11.25rem"
      height="100%"
    />
  </HStack>
);
