import { Text, VStack } from '@chakra-ui/react';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

export const NoShipmentFound: FunctionComponent = () => {
  const { t } = useTranslation();
  return (
    <VStack color="gray.500" paddingBottom="16">
      <Text fontSize="22">{t('No Shipments found')}</Text>
    </VStack>
  );
};
