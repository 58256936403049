import { Icon, IconProps } from '@chakra-ui/react';

export const CheckCircleIcon = (props: IconProps) => (
  <Icon viewBox="0 0 40 40" {...props}>
    <g fill="none" fillRule="evenodd">
      {/*fill = color, change to config*/}
      <rect fill="currentColor" width="40" height="40" rx="20" />
      <path d="M8 8h24v24H8z" />
      <path
        d="M25 14a1 1 0 0 1 3 2l-9 10h-3l-4-4a2 2 0 0 1 2-2h1l2 3 8-9Z"
        fill="#FFF"
        fillRule="nonzero"
      />
    </g>
  </Icon> 
);
