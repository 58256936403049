import { Global } from '@emotion/react';

export const Fonts = () => {
  const fonts = CONFIG.fonts.map(
    (font) => `@font-face {
      font-family: 'Default Font';
      font-style: normal;
      font-weight: ${font.weight};
      font-display: swap;
      src: local(''), url('/fonts/${font.name}.woff2') format('woff2'), url('/fonts/${font.name}.woff') format('woff');
      unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }`,
  );

  return <Global styles={fonts.join('\n')} />;
};
