import { LocationType } from '../enums/LocationType';
import { ShipmentStatus } from '../enums/ShipmentStatus';
import { TransportMode } from '../enums/TransportMode';
import { Shipment } from '../types/Shipment';

export const singleCountryCheckedShipment: Shipment = {
  origin: 'Rio De Janeiro',
  destination: 'Visakhapatanam',
  reference: 'T21190160006',
  status: ShipmentStatus.Delivered,
  transportMode: TransportMode.Sea,
  locations: [
    {
      id: 'LegID1',
      type: LocationType.Pickup,
      completed: true,
      city: 'Hamburg',
      country: 'Germany',
      countryCode: 'de',
      date: new Date('2023-02-01'),
    },
    {
      id: 'LegID2',
      type: LocationType.Departure,
      completed: true,
      city: 'Hamburg',
      country: 'Germany',
      countryCode: 'de',
      date: new Date('2023-02-02'),
    },
    {
      id: 'LegID3',
      type: LocationType.Arrival,
      completed: true,
      city: 'Bremen',
      country: 'Germany',
      countryCode: 'de',
      date: new Date('2023-02-10'),
    },
    {
      id: 'LegID4',
      type: LocationType.Customs,
      completed: true,
      city: 'Bremen',
      country: 'Germany',
      countryCode: 'de',
      date: new Date('2023-02-10'),
    },
    {
      id: 'LegID5',
      type: LocationType.Delivery,
      completed: true,
      city: 'Bremen',
      country: 'Germany',
      countryCode: 'de',
      date: new Date('2023-02-11'),
    },
  ],
};
