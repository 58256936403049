import { ChakraProvider } from '@chakra-ui/react';
import React from 'react';
import { createRoot } from 'react-dom/client';
import './translations/i18n';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { App } from './App';
import { LegacyRedirect } from './components/LegacyRedirect';
import { theme } from './theme';
import { Fonts } from './theme/Fonts';
import { AppRoutes } from './utils/AppRoutes';

const root = createRoot(document.getElementById('root')!);

root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <Fonts />
      <BrowserRouter>
        <Routes>
          <Route path={AppRoutes.base} element={<App />} />
          <Route path={AppRoutes.entity.base} element={<App />} />
          <Route path={AppRoutes.Shipment.base} element={<App />} />
          <Route
            path={AppRoutes.legacyRoutes.trackntraceLight}
            element={
              <LegacyRedirect searchParameterName="ShipingnumberLight" />
            }
          />
          <Route path="*" element={<Navigate to={AppRoutes.base} />} />
        </Routes>
      </BrowserRouter>
    </ChakraProvider>
  </React.StrictMode>,
);
