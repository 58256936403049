import { ComponentStyleConfig } from '@chakra-ui/react';

export const Divider: ComponentStyleConfig = {
  baseStyle: {
    borderColor: 'primary.500',
  },
  variants: {
    vertical: {
      marginTop: '0 !important',
      marginBottom: '-5rem !important',
      borderStyle: 'solid',
      borderLeftWidth: '3px',
      opacity: '1',
    },
  },
};
