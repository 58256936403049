import { FunctionComponent } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { AppRoutes } from '../utils/AppRoutes';

interface ILegacyRedirectProps {
  searchParameterName: string;
}

export const LegacyRedirect: FunctionComponent<ILegacyRedirectProps> = ({
  searchParameterName,
}) => {
  const [searchParams] = useSearchParams();

  const shipmentNumber = searchParams.get(searchParameterName);

  if (shipmentNumber != null) {
    return <Navigate to={`${AppRoutes.base}?q=${shipmentNumber}`} />;
  }

  return <Navigate to={AppRoutes.base} />;
};
