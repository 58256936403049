import { Container, Flex, Spinner, VStack } from '@chakra-ui/react';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useSearchParams, useParams } from 'react-router-dom';
import { Footer } from './components/Footer';
import { Header } from './components/Header';
import { MainInformation } from './components/MainInformation';
import { NoShipmentFound } from './components/NoShipmentFound';
import { Timeline } from './components/TimeLine';
import { UpsellSection } from './components/UpsellSection';
import { setMockShipment } from './mockShipments';
import { Shipment } from './types/Shipment';

export const App: FunctionComponent = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [shipment, setShipment] = useState<Shipment | null>(null);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [search, setSearch] = useState<string>(searchParams.get('q') ?? '');
  const [shipmentNotFound, setShipmentNotFound] = useState<boolean>(false);
  const { entityId } = useParams();
  const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

  const titleAndSearch = useCallback(() => {
    const searchParam = searchParams.get('q') ?? '';
    setSearch(searchParam);
    document.title = `${
      shipment?.reference && searchParam !== ''
        ? shipment?.reference + ' • '
        : ''
    }${CONFIG.texts.title}`;
  }, [searchParams, shipment?.reference]);

  useEffect(() => {
    titleAndSearch();
  }, [titleAndSearch]);

  const resetSearchParams = () => {
    setSearchParams({});
  };

  const onSearchShipment = async (searchString: string) => {
    setShipmentNotFound(false);
    setShipment(null);
    if (!apiEndpoint) {
      throw Error('API Endpoint is not set');
    }
    if (searchString !== '') {
      setIsSearching(true);
      setSearchParams({ q: searchString });

      // MOCK TESTING
      if (searchString.startsWith('TEST_')) {
        await new Promise((f) => setTimeout(f, 500));
        setMockShipment(searchString, setShipment, setShipmentNotFound);
        setIsSearching(false);
      } else {
        let searchWithEntity: string =
          apiEndpoint + searchString.replaceAll('#', '%23');
        if (entityId) searchWithEntity += `&entity=${entityId}`;
        const response = await fetch(searchWithEntity);
        setIsSearching(false);
        if (!response.ok) {
          setShipmentNotFound(true);
          return;
        }

        const jsonShipment: Shipment = (await response.json()) as Shipment;
        if (!jsonShipment || !jsonShipment.reference) {
          setShipmentNotFound(true);
        } else {
          setShipment(jsonShipment);
        }
      }
    } else {
      resetSearchParams();
    }
  };

  useEffect(() => {
    if (search) {
      onSearchShipment(search);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex
      flexDirection="column"
      justifyContent="space-between"
      minHeight="100vh"
    >
      <Container maxWidth="md">
        <Header />
        <MainInformation
          shipment={shipment}
          search={search}
          setSearch={setSearch}
          onSearchShipment={onSearchShipment}
          resetSearchParams={resetSearchParams}
        />
        <Timeline shipment={shipment} />
        {shipmentNotFound && <NoShipmentFound />}
        {isSearching && (
          <VStack>
            <Spinner size="xl" color="gray.500" />
          </VStack>
        )}
      </Container>
      <Container maxW="100%" padding="0">
        <UpsellSection />
        <Footer />
      </Container>
    </Flex>
  );
};
