import { chakra, VStack } from '@chakra-ui/react';

export const UpsellContainer = chakra(VStack, {
  baseStyle: {
    justifyContent: 'center',
    width: '100%',
    height: '60',
    color: 'white',
    paddingX: '2',
  },
});
