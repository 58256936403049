import { ChevronRightIcon } from '@chakra-ui/icons';
import { Text, Box } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { UpsellContainer } from '../theme/customComponents/UpsellContainer';
import { UpsellLink } from '../theme/customComponents/UpsellLink';

export const UpsellSection = () => {
  const { t } = useTranslation();
  return (
    <Box background="secondary.500">
      <UpsellContainer spacing="8">
        <Text fontSize="xl" textAlign="center" mx="5">
          {t('upsellHeader')}
        </Text>
        <UpsellLink href={CONFIG.links.upsell.link} target="_blank">
          <Box as="span" />
          <Box as="span">{t(CONFIG.links.upsell.title)}</Box>
          <ChevronRightIcon width="6" height="6" />
        </UpsellLink>
      </UpsellContainer>
    </Box>
  );
};
