import { ComponentStyleConfig } from '@chakra-ui/react';

export const Input: ComponentStyleConfig = {
  baseStyle: {},
  variants: {
    search: {
      field: {
        borderRadius: '28',
        border: '1px',
        borderColor: 'gray.600',
        bg: 'blackAlpha.600',
        fontSize: 'sm',
      },
    },
  },
};
