import { Flex, VStack } from '@chakra-ui/react';
import { FunctionComponent } from 'react';
import { Shipment } from '../../types/Shipment';
import { TimelineElement } from './TimelineElement';

interface ITimelineProps {
  shipment: Shipment | null;
}

export const Timeline: FunctionComponent<ITimelineProps> = ({ shipment }) => (
  <Flex
    flexDirection="column"
    as="section"
    alignItems="center"
    paddingLeft="4"
    mt="14"
  >
    <VStack>
      {shipment?.locations?.map((location, index) => (
        <TimelineElement
          key={location.id}
          locationInformation={location}
          isLast={index === (shipment?.locations?.length ?? 1) - 1}
        />
      ))}
    </VStack>
  </Flex>
);
