export const AppRoutes = {
  base: '/',
  entity: {
    base: `/:entityId/Shipment`,
  },
  Shipment: {
    base: `/Shipment`,
  },
  legacyRoutes: {
    trackntraceLight: 'track-n-trace-light.php',
  },
};
